<template>
  <sb-page-content title="Work Authorisations">
    <v-container class="pa-0">
      <v-toolbar dark
                 color="secondary"
                 class="toolbar-rounded search-padding">
        <v-text-field v-model="search.text"
                      clearable
                      flat
                      solo
                      light
                      hide-details
                      prepend-inner-icon="mdi-magnify"
                      label="Search"
                      @click:clear="clearSearch" />
        <v-spacer />
        <sb-date-range-picker-field v-model="search.dateRange"
                                    ref-name="workAuthorisationDateRange"
                                    clearable
                                    sort-dates
                                    sb-solo
                                    @clear="clearDateRangeSearch"
                                    @change="dateSelectedChange" />
        <v-tooltip bottom>
          <template #activator="{on}">
            <v-btn icon
                   class="mx-3"
                   v-on="on"
                   @click="refreshList">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{on}">
            <v-btn icon
                   class="mx-3"
                   v-on="on"
                   @click="showHelp = !showHelp">
              <v-icon>mdi-help</v-icon>
            </v-btn>
          </template>
          <span>Search Help</span>
        </v-tooltip>
      </v-toolbar>

      <v-expand-transition>
        <v-sheet v-if="showHelp"
                 color="accent"
                 elevation="3"
                 height="500"
                 width="100%"
                 dark>
          <v-container class="px-7 pt-0">
            <v-row>
              <v-col cols="12"
                     class="py-0">
                <v-row align="center">
                  <v-col class="py-0">
                    <div class="text-h4 mt-5 white--text">
                      Search Help
                    </div>
                  </v-col>
                  <v-col class="py-0">
                    <v-tooltip bottom>
                      <template #activator="{on}">
                        <v-btn text
                               fab
                               class="text-h4 mt-5 float-right"
                               v-on="on"
                               @click="showHelp = !showHelp">
                          <span>X</span>
                        </v-btn>
                      </template>
                      <span>Close</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <div class="text-caption">
                  The following fields can be searched on:
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"
                     sm="6">
                <ul>
                  <li>Job Number</li>
                  <li>Service Request Number</li>
                  <li>Created Timestamp</li>
                  <li>Service Type</li>
                  <li>External Reference Status</li>
                  <li>Severity</li>
                  <li>Customer Name</li>
                </ul>
              </v-col>
              <v-col cols="12"
                     sm="6">
                <ul>
                  <li>Vehicle Registration Number</li>
                  <li>External Reference Id</li>
                  <li>Current Task</li>
                  <li>Authorisation Status</li>
                  <li>Authorisation Number</li>
                  <li>Authorisation Message</li>
                </ul>
              </v-col>
            </v-row>

            <v-row>
              <v-divider />
            </v-row>

            <v-row>
              <v-col cols="12"
                     class="py-0">
                <div class="text-caption mt-3">
                  Authorisation Statuses:
                </div>
              </v-col>

              <v-col cols="12"
                     sm="6">
                <ul>
                  <li>In Progress</li>
                  <li>Validation Error</li>
                  <li>Validation Failed</li>
                  <li>Authorisation Failed</li>
                  <li>Authorised</li>
                </ul>
              </v-col>
              <v-col cols="12"
                     sm="6">
                <ul>
                  <li>Invoiced</li>
                  <li>Completed</li>
                  <li>Cancelled</li>
                  <li>Error</li>
                </ul>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </v-expand-transition>

      <sb-list :items="serviceRequests"
               :loading="$wait.is('serviceRequestsLoading')"
               :page.sync="pagination.number"
               :items-per-page.sync="pagination.numberOfElements"
               :total-pages="pagination.totalPages"
               empty-message="No Authorisations"
               empty-sub-message="No Authorisations available at this time or try relaxing your search parameters"
               list-item-component="SbWorkAuthorisationListItem"
               class="mt-2" />
    </v-container>
  </sb-page-content>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mapWaitingActions } from 'vue-wait';

import SbList from '@/components/core/SbList';

import { SR_STATUSES, SR_APPLICATION_TYPES, AUTH_STATUSES } from '@/config/constants';

import Pagination from '@/models/Pagination';

export default {
  components: {
    SbList,
  },

  data() {
    return {
      search: {
        text: '',
        dateRange: [],
      },
      filterStatus: 'SR_STATUS_OPEN',
      filterAuthStatus: 'Validation Failed',
      pagination: new Pagination(),
      showHelp: false,
    };
  },

  computed: {
    ...mapGetters({
      serviceRequests: 'serviceRequests/serviceRequests',
    }),

    serviceRequestStatuses() {
      return Object.values(SR_STATUSES).filter((x) => !x.hidden);
    },

    authorisationStatuses() {
      return Object.values(AUTH_STATUSES);
    },
  },

  watch: {
    'search.text': {
      handler(value) {
        // eslint-disable-next-line
        value && this.getServiceRequestsFiltered();
      },
    },

    'pagination.number': {
      handler() {
        this.getServiceRequestsFiltered();
      },
    },

    'pagination.numberOfElements': {
      handler() {
        this.getServiceRequestsFiltered();
      },
    },
  },

  async created() {
    await this.getServiceRequestsFiltered();
  },

  beforeDestroy() {
    this.$store.commit('serviceRequests/serviceRequests', []);
  },

  methods: {
    ...mapActions('serviceRequests', ['getServiceRequests']),

    ...mapWaitingActions('serviceRequests', {
      getServiceRequests: 'serviceRequestsLoading',
    }),

    clearSearch() {
      this.search.text = '';
      this.getServiceRequestsFiltered();
    },

    clearDateRangeSearch() {
      this.search.dateRange = [];
      this.getServiceRequestsFiltered();
    },

    async getServiceRequestsFiltered() {
      const result = await this.getServiceRequests({
        status: this.filterStatus,
        srType: SR_APPLICATION_TYPES.SR_APPLICATION_TYPE_AUTHORISATIONS,
        authUser: this.authUser,
        filterValue: this.search.text || '',
        page: this.pagination.number,
        pageSize: this.pagination.numberOfElements,
        dateRange: this.search.dateRange,
      });

      this.pagination = result.pagination;

      return result;
    },

    dateSelectedChange() {
      this.getServiceRequestsFiltered();
    },

    refreshList() {
      this.getServiceRequestsFiltered();
    },

  },
};
</script>
